import clsx from 'clsx'
import React, { KeyboardEvent, MouseEvent, RefObject } from 'react'

import styles from 'components/brief/questionInput/QuestionInput.module.scss'

// import { useAssistant } from '../../../../hooks/useAssistant'
import InputActions from '../inputActions/InputActions'

interface QuestionInterfaceProps {
  enableEdit: boolean
  defaultValue: string
  onInput: () => void
  onQuestionKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  inputRef: RefObject<HTMLDivElement>
  questionCharCount: number
  question: string
  answerIsLoading: boolean
}

export const QuestionInput = ({
  enableEdit,
  defaultValue,
  onInput,
  onQuestionKeyDown,
  onMouseDown,
  inputRef,
  questionCharCount,
  question,
  answerIsLoading,
}: QuestionInterfaceProps) => {
  const handleInput = () => {
    onInput()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    onQuestionKeyDown(event)
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    onMouseDown(event)
  }

  const setCursorToIndex = (element: HTMLElement, index: number) => {
    const range = document.createRange()
    const selection = window.getSelection()

    range.setStart(element.childNodes[element.childNodes.length - 1], index)
    range.collapse(true)
    selection?.removeAllRanges()
    selection?.addRange(range)
  }

  function replacePasteText(originalText: string, firstIndex: number, lastIndex: number, replacement: string) {
    return originalText.substring(0, firstIndex) + replacement + originalText.substring(lastIndex, originalText.length)
  }

  const handlePaste = (e: any) => {
    try {
      e.preventDefault()

      const selection = window.getSelection() as any
      if (selection) {
        const firstIndex = Math.min(selection?.baseOffset, selection?.extentOffset)
        const lastIndex = Math.max(selection?.baseOffset, selection?.extentOffset)

        const text = e.clipboardData.getData('text/plain')

        if (inputRef.current) {
          const newText = replacePasteText(inputRef.current.innerText, firstIndex, lastIndex, text)
          inputRef.current.innerText = newText

          const indexDiff = Math.abs(firstIndex - lastIndex)

          setCursorToIndex(inputRef.current, lastIndex - indexDiff + text.length)
          onInput()
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={styles.inputContainer}>
      <div
        ref={inputRef}
        contentEditable={enableEdit}
        className={clsx(styles.questionInput, !enableEdit && styles.disabled)}
        // placeholder="Select a Prompt or type text here..."
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        onMouseDown={handleMouseDown}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: defaultValue }}
        onPaste={handlePaste}
      />
      <InputActions question={question} questionCharCount={questionCharCount} answerIsLoading={answerIsLoading} />
    </div>
  )
}
