import { WppActionButton } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useEffect, useState } from 'react'

import { useGetChats } from 'api/brief/queries/chats/useGetChats'
import { useGetMessages } from 'api/brief/queries/chats/useGetMessages'
import { Flex } from 'components/common/flex/Flex'
import { ChatProvider } from 'contexts/ChatContext'

import styles from './Brief.module.scss'
import BriefAssistant from './BriefAssistant'
import BriefPreview from './BriefPreview'

export function Brief() {
  return (
    <Flex direction="row" gap={8}>
      <BriefAssistant />
      <div className={styles.verticalDivider} />
      <BriefPreview />
    </Flex>
  )
}

export default function BriefContainer() {
  const [activeTaskId, setActiveTaskId] = useState(localStorage.getItem('activeTaskId') || crypto.randomUUID())

  const { osContext } = useOs()
  const tenantId = osContext.tenant.id
  const { id: userId } = osContext.userDetails

  const { data: chats, isLoading: isLoadingChats } = useGetChats({
    params: { userId, tenantId, taskId: activeTaskId },
  })

  const { data: messages, isLoading: isLoadingMessages } = useGetMessages({
    params: { taskId: activeTaskId, chatId: chats?.[0]?.chat.id || '', page: 1, itemsPerPage: 100 },
    enabled: !!chats?.[0]?.chat.id,
  })

  useEffect(() => {
    if (activeTaskId !== localStorage.getItem('activeTaskId')) {
      localStorage.setItem('activeTaskId', activeTaskId)
    }
  }, [activeTaskId])

  if (isLoadingChats || isLoadingMessages) {
    return <div>Loading...</div>
  }

  return (
    <ChatProvider
      value={{
        chatsWithGatheredData: chats,
        isLoadingChats,
        messagesWithGatheredData: messages,
        isLoadingMessages,
        chatId: chats?.[0]?.chat.id || '',
        taskId: activeTaskId,
      }}
    >
      <>
        <WppActionButton
          type="button"
          variant="destructive"
          onClick={() => {
            const taskId = crypto.randomUUID()
            setActiveTaskId(taskId)
          }}
        >
          Generate taskId
        </WppActionButton>
        <Brief />
      </>
    </ChatProvider>
  )
}
