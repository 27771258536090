import { WppActionButton, WppCard, WppIconEdit, WppTypography } from '@platform-ui-kit/components-library-react'

import { useChatContext } from 'hooks/useChatContext'
import { camelCaseToLabel } from 'utils/common'

import styles from './Brief.module.scss'
import PreviewNoContent from './PreviewNoContent'

export default function BriefPreview() {
  const { isLoadingChats, messagesWithGatheredData, isLoadingMessages } = useChatContext()
  const isLoading = isLoadingChats || isLoadingMessages
  return (
    <WppCard variant="secondary" title="Brief Preview" size="l" className={styles.briefPreviewContainer}>
      {!isLoading && !messagesWithGatheredData?.data?.gatheredData && <PreviewNoContent />}
      {!isLoading && !!messagesWithGatheredData?.data?.gatheredData && (
        <>
          <div slot="header">
            <WppTypography type="l-strong">Brief Preview</WppTypography>
          </div>
          <div slot="actions">
            <WppActionButton disabled variant="secondary">
              <WppIconEdit />
            </WppActionButton>
          </div>
          <div className={styles.briefPreviewContent}>
            <div className={styles.briefPreviewOverview}>
              <WppTypography type="l-strong">Overview:</WppTypography>
              {messagesWithGatheredData.data.gatheredData?.whyThisBrief?.reason && (
                <div>
                  <WppTypography type="m-strong">Why this Brief:</WppTypography>
                  <div>
                    <WppTypography tag="p">
                      {messagesWithGatheredData.data.gatheredData?.whyThisBrief?.reason}
                    </WppTypography>
                  </div>
                </div>
              )}
              {messagesWithGatheredData.data.gatheredData?.targetAudience && (
                <div>
                  <WppTypography type="m-strong">Target Audience:</WppTypography>
                  <div>
                    <WppTypography tag="p">
                      {messagesWithGatheredData.data.gatheredData?.targetAudience?.description}
                    </WppTypography>
                  </div>
                </div>
              )}
              {messagesWithGatheredData.data.gatheredData?.successMeasurement && (
                <>
                  <WppTypography type="l-strong">Success Measurement:</WppTypography>
                  {Object.keys(
                    messagesWithGatheredData.data.gatheredData.successMeasurement as { [key: string]: any },
                  ).map(key => (
                    <div key={key}>
                      <WppTypography type="m-strong">{camelCaseToLabel(key)}:</WppTypography>
                      <div>
                        <WppTypography tag="p">
                          {
                            messagesWithGatheredData.data.gatheredData.successMeasurement[
                              key as keyof typeof messagesWithGatheredData.data.gatheredData.successMeasurement
                            ]
                          }
                        </WppTypography>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {messagesWithGatheredData.data.gatheredData?.opportunityOrProblem && (
                <>
                  <WppTypography type="l-strong">Opportunity Or Problem:</WppTypography>
                  {Object.keys(
                    messagesWithGatheredData.data.gatheredData.opportunityOrProblem as { [key: string]: any },
                  ).map(key => (
                    <div key={key}>
                      <WppTypography type="m-strong">{camelCaseToLabel(key)}:</WppTypography>
                      <div>
                        <WppTypography tag="p">
                          {
                            messagesWithGatheredData.data.gatheredData.opportunityOrProblem[
                              key as keyof typeof messagesWithGatheredData.data.gatheredData.opportunityOrProblem
                            ]
                          }
                        </WppTypography>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className={styles.briefPreviewImage}>
              <svg width="258" height="259" viewBox="0 0 258 259" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="258" height="259" fill="#E3E3E3" />
              </svg>
            </div>
          </div>
        </>
      )}
    </WppCard>
  )
}
