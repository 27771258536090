import { useOs } from '@wpp-open/react'
import { isAxiosError } from 'axios'
import { useCallback, useMemo } from 'react'

import { useCreateChat } from 'api/brief/mutations/chats/useCreateChat'
import { useSendMessageChat } from 'api/brief/mutations/chats/useSendMessageChat'

interface Props {
  activeTaskId?: string
  activeChatId?: string
}

let isLoadingMutation = false

export const useAssistant = ({ activeTaskId = '', activeChatId = '' }: Props = {}) => {
  const { osContext } = useOs()
  const tenantId = osContext.tenant.id
  const { id: userId } = osContext.userDetails

  const { mutateAsync: createChat } = useCreateChat()
  const { mutateAsync: sendMessageChat } = useSendMessageChat()

  const startConversation = useCallback(
    async ({ taskId }: { taskId: string }): Promise<any | number | undefined> => {
      try {
        isLoadingMutation = true
        const response = await createChat({
          tenantId,
          userId,
          taskId,
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      } finally {
        isLoadingMutation = false
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createChat],
  )

  const sendMessage = useCallback(
    async ({ message }: { message: string }): Promise<any | number | undefined> => {
      try {
        isLoadingMutation = true
        const response = await sendMessageChat({
          role: 'user',
          type: 'OPEN_API',
          content: message,
          chatId: activeChatId,
          taskId: activeTaskId,
        })
        return response.data
      } catch (err) {
        if (isAxiosError(err)) {
          return err.response?.status
        }
        console.error(err)
      } finally {
        isLoadingMutation = false
      }
    },
    [sendMessageChat, activeChatId, activeTaskId],
  )

  return useMemo(
    () => ({
      startConversation,
      sendMessage,
      isLoadingMutation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startConversation, sendMessage, isLoadingMutation],
  )
}
