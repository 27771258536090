import { WppSpinner } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useEffect, useRef, useState } from 'react'

import { queryClient } from 'app/Root'
import { Flex } from 'components/common/flex/Flex'
import { useAssistant } from 'hooks/useAssistant'
import { useChatContext } from 'hooks/useChatContext'

import styles from './Brief.module.scss'
import { BriefChatMessages } from './BriefChatMessages'
// import { BriefPrimaryGoalQuestion } from './BriefPrimaryGoalQuestion'
import { QuestionInput } from './questionInput/QuestionInput'
import WelcomeBriefAssistant from './steps/WelcomeBriefAssistent'

export default function BriefAssistant() {
  const inputRef = useRef<HTMLDivElement>(null)
  const refBubbles = useRef({} as any)
  const refScrollBottomDiv = useRef<HTMLDivElement>(null)
  const [question, setQuestion] = useState('')

  const { chatsWithGatheredData, chatId, isLoadingChats, messagesWithGatheredData, isLoadingMessages, taskId } =
    useChatContext()

  const { sendMessage, isLoadingMutation, startConversation } = useAssistant({
    activeTaskId: taskId,
    activeChatId: chatId,
  })

  const handleStartConversation = () => {
    startConversation({ taskId })
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const scrollToBottom = useCallback(
    (id?: string) => {
      if (id) {
        const scrollImmediateTimeout = setTimeout(() => {
          if (messagesWithGatheredData.messages.length > 0) {
            refBubbles.current[id]?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            })
          }
        }, 10)

        return () => {
          clearTimeout(scrollImmediateTimeout)
        }
      } else {
        const scrollImmediateTimeout = setTimeout(() => {
          if (messagesWithGatheredData.messages.length > 0) {
            refScrollBottomDiv.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            })
          }
        }, 10)

        return () => {
          clearTimeout(scrollImmediateTimeout)
        }
      }
    },
    [messagesWithGatheredData.messages.length],
  )

  useEffect(() => {
    if (messagesWithGatheredData?.messages?.length > 0 && !!messagesWithGatheredData?.data?.gatheredData) {
      queryClient.invalidateQueries(['chats'])
      queryClient.refetchQueries(['chats'])
    }
    if (messagesWithGatheredData?.messages?.length > 0) {
      scrollToBottom(messagesWithGatheredData.messages[messagesWithGatheredData.messages.length - 1].id)
      setQuestion('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesWithGatheredData?.messages.length])

  useEffect(() => {
    if (isLoadingMutation) {
      scrollToBottom()
    }
  }, [isLoadingMutation, scrollToBottom])

  useEffect(() => {
    scrollToBottom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.briefAssistantContainer}>
      {(isLoadingChats || isLoadingMessages) && !isLoadingMutation ? (
        <Flex style={{ width: '100%' }} justify="center" align="center">
          <WppSpinner size="m" />
        </Flex>
      ) : (
        <Flex className={styles.chatDisplay} direction="column" gap={12}>
          {chatsWithGatheredData.length === 0 && (
            <WelcomeBriefAssistant onStartConversation={handleStartConversation} />
          )}
          {/* {chatsWithGatheredData.length === 1 && messagesWithGatheredData.messages.length === 0 && (
            <BriefPrimaryGoalQuestion />
          )} */}
          {chatsWithGatheredData.length === 1 && messagesWithGatheredData.messages.length > 0 && (
            <BriefChatMessages question={question} refBubbles={refBubbles} />
          )}
          {isLoadingMutation && (
            <Flex style={{ width: '100%' }} justify="center" align="center">
              <WppSpinner size="m" />
            </Flex>
          )}
          <div ref={refScrollBottomDiv} />
        </Flex>
      )}
      <div>
        <form className={clsx(styles.questionInputWrapper, styles.questionInputWrapperFooterOffset)}>
          <div className={styles.inputBorder}>
            <QuestionInput
              enableEdit={
                !isLoadingMutation &&
                !isLoadingChats &&
                !isLoadingMessages &&
                !chatsWithGatheredData?.[0]?.data.requirementsFulfilled
              }
              defaultValue=""
              onInput={() => {
                let inputRefValue = inputRef?.current?.innerText ?? ''
                const findNewLine = inputRefValue.includes('\n')

                if (findNewLine && inputRefValue.length === 1 && inputRef?.current?.innerHTML !== undefined) {
                  inputRefValue = ''
                  inputRef.current.innerHTML = ''
                }
              }}
              onQuestionKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  const text = inputRef.current?.innerText || '-'
                  if (inputRef.current) {
                    inputRef.current.innerHTML = ''
                  }
                  setQuestion(text)
                  sendMessage({ message: text })
                }
              }}
              onMouseDown={handleMouseDown}
              inputRef={inputRef}
              questionCharCount={inputRef.current?.innerHTML.length || 0}
              question={inputRef.current?.innerHTML || ''}
              answerIsLoading={false}
            />
          </div>
        </form>
      </div>
    </div>
  )
}
