import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { assistantApi } from 'api'

const apiInstances = [assistantApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { osApi, osContext } = useOs()

  const tenantId = osContext.tenant.id

  useEffect(() => {
    const interceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
            // instanceConfig!.headers!.Authorization =
            //   'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSWTNGSWRndVNiSDZFYzJiUS0xQ2VwY1k5RDlXT1ZiWk15Vy1VdVQwUEFnIn0.eyJleHAiOjE3MTA4NzEwODMsImlhdCI6MTcxMDgzNTA4NCwiYXV0aF90aW1lIjoxNzEwODM1MDgzLCJqdGkiOiIwZTVkNTJiOS0zYjI2LTRmZjktYmEwOC04ZjFhYWQyYThiNWYiLCJpc3MiOiJodHRwczovL2F1dGgub3Mud3BwLmNvbS9hdXRoL3JlYWxtcy9hei1iZXRhIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImQ2ZDY3Y2JjLTBjMWYtNGIwNS1iMmM5LWJjMjk5ODE4NmIzMiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJveC1zdGFnaW5nLWZlIiwic2Vzc2lvbl9zdGF0ZSI6ImZkYmQwNzliLTA2OWUtNDFlNS04YTc3LTkzNWFjNWNiNzcyYSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIiwiZGVmYXVsdC1yb2xlcy1hei1iZXRhIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsInNpZCI6ImZkYmQwNzliLTA2OWUtNDFlNS04YTc3LTkzNWFjNWNiNzcyYSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IkRlbmlzIFN1Ym90aWMiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJkZW5pcy5zdWJvdGljQHdwcC5jb20iLCJnaXZlbl9uYW1lIjoiRGVuaXMiLCJmYW1pbHlfbmFtZSI6IlN1Ym90aWMiLCJlbWFpbCI6ImRlbmlzLnN1Ym90aWNAd3BwLmNvbSJ9.a0aHdoXzBZ5mivGOPLF_2liIQkTPei3kWEwOsDu3nvzIClaS2oVonNz844dPfiT_BHNCkKOK_LM3LrR-_s7n7E3tnJCitKNy0Ztv7Upr2y6X-CVT3T5M5V7ig8tvQJ52uuK5FAxPQKy1txhBHW5kG8l-xiS7GxODi2jv_XyLhkM35FFirt43EAmEuDO6PQ_1ltHBl9RzSJNdQRMwJ6R2DXrGsJ4MU9xd_Xtoogbn_DdIdWZJblZrmRNB3oWzTz6VgNH2dibvNnHOjohLgU4QuhE97eLIsD-LS0Qb9EP9vsZ-jAZ0-fNAvY2nSoL_eAEz7QCQE6XRk5nPYhB5IuXvlg'
          }

          if (tenantId) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenantId
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}
